export const environment = {
  production: false,
  baseUrl: 'https://planogeneticoqa.altagenetics.com.br',
  apiBaseUrl: 'https://planogeneticoqa.altagenetics.com.br/api',
  b2cRedirectUri: 'https://planogeneticoqa.altagenetics.com.br',
  b2cPostLogoutRedirectUri: 'https://planogeneticoqa.altagenetics.com.br',
  b2cClientId: 'eb411be5-e9bd-4b84-8998-47c4efd79919',
  b2cLogin: 'altab2c.b2clogin.com',
  b2cDomain: 'altab2c.onmicrosoft.com',
  b2cApiClientId: 'eb411be5-e9bd-4b84-8998-47c4efd79919',
  b2cSignUpSignIn: 'B2C_1_Common_SignupSignin_v1',
  b2cResetPassword: 'B2C_1_Common_PasswordReset_v1'
};
